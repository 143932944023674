
import { defineComponent } from 'vue';
import TableWrapper from '@/views/Payment/components/TableWrapper.vue';
import YearPicker from '@/views/Payment/components/YearPicker.vue';
import useDealerPayoutOverview from '@/hooks/payment/controlling/Fl/useFlPayoutOverview';
import { elementSize } from '@/utils';
import ApproveSteps from '@/views/Payment/components/ApproveSteps.vue';
import downloadFile from '@/utils/claim/downloadFile';

export default defineComponent({
    name: 'paymentTracking',
    components: {
        YearPicker,
        // TableWrapper,
        ApproveSteps,
    },
    props: {},
    setup() {
        const {
            entities,
            payoutStatuses,
            searchParams,
            doReset,
            columnsRT,
            columnsWS,
            tableDataRT,
            tableDataWS,
            doSearch,
            downloadPayoutCodeFile,
            approveVisible,
            approveClick,
            approveList,
        } = useDealerPayoutOverview();

        doSearch();

        const tableSize = elementSize('.ant-table-wrapper');

        // WS 下载
        const handleDownloadPayoutCode = (record: any) => {
            downloadFile(
                {
                    url: `/claimapi/payout/round/ws/export/${record.roundId}`,
                    // url: `/claimapi/payout/round/ws/downloadZip/8a8235657cb600a8017cb638d34800e5`,
                    method: 'get',
                },
                ''
            );
        };
        const handleDownloadPayoutRound = (record: any) => {
            downloadFile(
                {
                    url: `/claimapi/payout/round/ws/downloadZip/${record.roundId}`,
                    //url: `/claimapi/payout/round/ws/export/8a8235657cb600a8017cb638d34800e5`,
                    method: 'get',
                },
                ''
            );
        };
        return {
            entities,
            payoutStatuses,
            searchParams,
            doReset,
            tableDataRT,
            tableDataWS,
            columnsRT,
            columnsWS,
            doSearch,
            downloadPayoutCodeFile,
            tableSize,
            approveVisible,
            approveClick,
            approveList,
            handleDownloadPayoutCode,
            handleDownloadPayoutRound,
        };
    },
});
