import { reactive, watch, ref } from 'vue'
import moment from 'moment'
import { Moment } from 'moment'
import { PaginationAPIRes } from '@/views/Payment/types'
import { FLPayoutOverviewData } from "@/views/Payment/Controlling/types"
import * as API from "@/API/payment"
import useThousands from '@/utils/payment/useThousands'
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal"
import useWait from '@/utils/payment/useWait'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { ApproveData } from '@/views/Payment/types'
import { message } from 'ant-design-vue';

type SearchParams = {
  payoutYear: Moment | null;
  payoutMonth: Moment | null;
  fl?: string;
  entity?: string;
  status?: string;
}

const useDealerPayoutOverview = () => {
  const searchParams = reactive<SearchParams>({
    payoutYear: null,
    payoutMonth: null,
    fl: undefined,
    entity: undefined,
    status: undefined
  })

  const entities = ['BBAC', 'FBAC', 'MBCL', 'DENZA']

  const payoutStatuses = ref<string[]>()

  const wait = useWait()

  watch(() => searchParams.payoutYear, (value) => {
    if (value) {
      searchParams.payoutMonth?.year(value.year())
    }
  })

  const tableDataRT = ref<FLPayoutOverviewData[]>()
  const tableDataWS = ref<FLPayoutOverviewData[]>()

  const columnsRT = [
    { title: "Payout Round", dataIndex: "payoutRound", slots: { customRender: 'payoutRound' },width: 180},
    { title: "Payout Code", className:'column-no-wrap', dataIndex: "payoutCode", slots: { customRender: 'payoutCode' }, key: "payoutCode", width: 160},
    { title: "VIN No.", dataIndex: "vinNo", width: 100, align: 'right', customRender: useThousands()},
    { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",  width: 190, align: 'right', customRender: useThousandsAndTwoDecimal()},
    { title: 'Payout Status', dataIndex: "status", width: 120, align: 'center'},
    { title: "Last Update Date", dataIndex: "updatedAtCast", width: 150, align: 'center'},
    { title: "Approval Path", fixed: 'right', align: 'left', width: 140, slots: { customRender: "approvalPath"}}
  ]

  const columnsWS = [
    { title: "Payout Round", dataIndex: "payoutRound", width: 180},
    { title: "Payout Code", className:'column-no-wrap', dataIndex: "payoutCode", slots: { customRender: 'payoutCode' }, key: "payoutCode", width: 150},
    { title: "VIN No.", dataIndex: "vinNo",  width: 100, align: 'right', customRender: useThousands() },
    { title: "Payout Amount w/o Service VAT", dataIndex: "payoutAmountWVat",  width: 190, align: 'right', customRender: useThousandsAndTwoDecimal()},
    { title: 'Payout Status', dataIndex: "status", width: 120, align: 'center'},
    { title: "Last Update Date", dataIndex: "updatedAtCast", width: 150, align: 'center'},
  ]

  const doSearch = () => {
    wait.then(() => {
      const params = {
        year: parseInt(searchParams.payoutYear!.format('YYYY')),
        month: searchParams.payoutMonth ? parseInt(searchParams.payoutMonth.format('M')) : 0,
        entity: searchParams.entity ? searchParams.entity : undefined,
        type: '',
        status: searchParams.fl ? (searchParams.fl == 'MBAFC' ? 'AFC' : 'LC') : 'all',
        pageNumber: 1,
        pageSize: 10000,
        overviewStatus: searchParams.status ? searchParams.status : 'all'
      }
      params.type = 'RT'
      API.getFlPayoutOverviewList(params).then((res: PaginationAPIRes<FLPayoutOverviewData>) => {
        tableDataRT.value = res.content
      })
      params.type = 'WS'
      API.getFlPayoutOverviewList(params).then((res: PaginationAPIRes<FLPayoutOverviewData>) => {
        tableDataWS.value = res.content
      })
    })
  }

  API.getDealerPayoutCondition().then((res) => {
    searchParams.payoutYear = moment().year(res.year)
    payoutStatuses.value = res.financeStatusList

    if (wait.toRun) {
      wait.toRun()
    }
  })

  const doReset = () => {
    API.getDealerPayoutCondition().then((res) => {
      searchParams.payoutYear = moment().year(res.year)
      searchParams.payoutMonth =  null
      searchParams.fl = undefined
      searchParams.entity = undefined
      searchParams.status = undefined
    }).then(() => doSearch())
  }

  const downloadPayoutCodeFile = (payoutCode: string) => {
    API.downloadOverviewPayoutCodeSheet(payoutCode).then((res: any) => {
      downloadFromStream(res)
    })
  }

  const approveList = ref<ApproveData[]>()
  const approveVisible = ref(false)
  const approveClick = (record: any) => {
    approveList.value = []
    // API.getApprovalPackage(record.id).then((res: any) => {
    //   if (res.code === '0') {
    //     approveList.value = res.data.nodeList
    //     approveVisible.value = true
    //   } else {
    //     message.error(res.message)
    //   }
    // })
    const params = {
      approvalType: "PayoutApproval",
      payoutCodeId: record.id,
      payoutType: "FL"
    }
    API.getApprovalPackageByCreated(params).then((res: any) => {
        if (res.code === '0') {
            approveList.value = res.data.map((item: any) => item.nodeList)
            approveVisible.value = true
        } else {
            message.error(res.message)
        }
    })
  }

  return {
    entities,
    payoutStatuses,
    searchParams,
    doReset,
    columnsRT,
    columnsWS,
    tableDataRT,
    tableDataWS,
    doSearch,
    downloadPayoutCodeFile,
    approveVisible,
    approveClick,
    approveList
  }
}

export default useDealerPayoutOverview